var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{
        associated: _vm.is_associated,
        active: _vm.is_active,
        special: _vm.face_id === 0,
        starting: _vm.starting,
        stopping: _vm.stopping,
        'archived-background': !_vm.is_associated && _vm.face_id !== 0,
    }},[(_vm.is_associated)?[(_vm.is_active)?_c('stop-button',{key:_vm.component.id,attrs:{"loading":_vm.stopping},nativeOn:{"click":function($event){return _vm.handleStopTimer.apply(null, arguments)}}}):_vm._e(),(_vm.project)?_c('div',{key:_vm.face_id,staticClass:"info"},[_c('component-info',{attrs:{"component":_vm.component}})],1):_vm._e(),(!_vm.is_active)?_c('div',{staticClass:"actions"},[_c('button',{staticClass:"play",class:{disabled: !_vm.can_track_time, starting: _vm.starting},on:{"click":_vm.handleStartTimer}},[(_vm.starting)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"spinner"}):_vm._e(),_c('svgicon',{attrs:{"name":"play"}})],1),_c('button',{staticClass:"cross",on:{"click":_vm.handleRemoveAssociation}},[_c('svgicon',{attrs:{"name":"cross"}})],1)]):(_vm.project)?_c('div',[_c('time-spent',{attrs:{"component":_vm.component,"show_invoiced":false}})],1):_vm._e()]:(_vm.face_id === 0)?[_c('div',{staticClass:"info",on:{"click":_vm.handleStopTimer}},[_c('span',[(_vm.is_active)?_c('strong',[_vm._v("No tracker is running.")]):_c('strong',[_vm._v("STOP active tracker.")])])])]:[_c('div',{staticClass:"info",on:{"click":function($event){return _vm.$emit('showTrackerDetail', _vm.face_id)}}},[_c('span',[_vm._v(" Click to assign to a project ")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }