var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.show,"width":"60%"},on:{"update:visible":function($event){_vm.show=$event},"closed":_vm.onCloseDialog}},[(_vm.face_id)?_c('div',{staticClass:"modal-content"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.selected_project)?_c('project-selector',{on:{"onSelection":(value) => {
                        _vm.selected_project = value;
                    }}}):(_vm.selected_project && !_vm.selected_stage)?_c('stage-selector',{attrs:{"selected_project":_vm.selected_project},on:{"onBack":function($event){_vm.selected_project = null},"onSelection":(value) => {
                        // The selected object it's a component, not a stage
                        if (value.stage) {
                            _vm.selected_stage = null;
                            _vm.handleAssociation(value);
                        } else {
                            _vm.selected_stage = value;
                        }
                    }}}):(_vm.selected_stage)?_c('component-selector',{attrs:{"selected_project":_vm.selected_project,"selected_stage":_vm.selected_stage},on:{"onBack":function($event){_vm.selected_stage = null},"onSelection":_vm.handleAssociation}}):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }